import React from 'react'
import { Link } from 'gatsby'
import * as styles from './header.module.scss'

type Props = {
  siteTitle: string
}

const Header: React.FC<Props> = ({ siteTitle }) => (
  <header className={styles.header}>
    <div className="container">
      <h1>
        <Link to="/" className={styles.title}>
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
